import axios, { AxiosError } from 'axios'
import { SelectOption } from '../common/types'
import { getPathsClientId, sanatizeNumericValue } from '../common/utils'
import { StandardAction, StandardThunkAction } from '../store/store'
import { updatePortfolioRiskParameter } from './investmentPortfolio'
import { addError } from './notifications'
import { 
    STANDARD_LIVING_RISK_LOADING,
    STANDARD_LIVING_RISK_RECEIVED,
    YEARS_TO_FINANCIAL_GOAL,
    YEARS_OF_FINANCIAL_GOAL,
    DISCOUNT_RATE,
    INFLATION_RATE,
    INVESTMENT_PORTFOLIO_TODAY,
    CURRENT_ANNUAL_SAVINGS,
    PLANNED_ANNUAL_SAVINGS,
    DESIRED_ANNUAL_FUNDING,
    INVESTMENT_PORTFOLIO_UPDATE,
    SLR_PAGE_INDEX,
    STANDARD_LIVING_RISK_UPDATE,
    DESIRED_ANNUAL_WANTS,
    DESIRED_ANNUAL_WISHES,
    MONTECARLO_NEEDS_THRESHOLD,
    MONTECARLO_WANTS_THRESHOLD,
    MONTECARLO_WISHES_THRESHOLD,
} from "./types/actions"
import { IrregularItem, IrregularItemType, FundingType, UpdateStandardLivingRiskRequest, Bucket, NeedsPortfolioMethod, NeedsImmunizationPeriod, WantsPortfolioModel, WishesPortfolioModel, MontecarloResponse, MontecarloPercentiles, MontecarloResultSet } from './types/standardLivingRisk'
import { ModelItemOption, Portfolio, TickerAllocation } from './types/portfolioDesigner'
import { getPortfolioFromTickerAllocations } from './portfolioDesigner'
import { getRecommendedPortfolio } from './riskPreferences'
import { GetStateFunction } from '../reducers'
import Highcharts from 'highcharts';

export const CLIENTS_ENDPOINT = '/api/clients'
export const STANDARD_LIVING_RISK_ENDPOINT = 'standardLivingRisk'
export const IRREGULAR_ITEM_ENDPOINT = `${STANDARD_LIVING_RISK_ENDPOINT}/irregularItems`
export const MONTECARLO_URL = `${process.env.REACT_APP_ANALYZER_API_URL || ''}/api/analyzer/montecarlo`

export const MONTECARLO_GRAPH_ENDPOINT = 'montecarlo'

export const SLR_ERROR_MAPPING: { [key: string]: string } = {
    'Either Needs Discount Rate or Needs Portfolio Method needs to be supplied': 'Please check your ALM Assumptions and confirm that your selected model has all the fields entered.',
}

interface NeedChartOptions {
    showLegend?: boolean,
}

export const getStandardLivingRisk = (): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        try {
            dispatch({ type: STANDARD_LIVING_RISK_LOADING })

            const profileId = getPathsClientId()
            if(!profileId) {
                return
            }
            const { data } = await axios.get(`${CLIENTS_ENDPOINT}/${profileId}/${STANDARD_LIVING_RISK_ENDPOINT}`)
            const { standardLivingRisk: standardLivingRiskPayload, wantsPortfolio, riskScores, success } = data
            if(!success) {
                return
            }
            
            const { 
                years_in_financial_goal,
                years_to_financial_goal,
                discount_rate,
                inflation_rate,
                investment_portfolio_today,
                current_annual_savings,
                planned_annual_savings_increase,
                desired_annual_funding,
                desired_annual_wants,
                desired_annual_wishes,
                standardLivingRisk,
                startYears,
                numberOfYears,
                irregularIncome,
                irregularExpenses,
                pv_savings,
                pv_liabilities,
                assets_discreationary_wealth,
                financial_plan_source,
                needs_portfolio,
                wants_portfolio,
                wishes_portfolio,
                montecarlo_percentiles,
                needs_immunization_period,
                needs_model_id,
                wants_model_id,
                wishes_model_id,
                montecarlo_needs_threshold,
                montecarlo_wants_threshold,
                montecarlo_wishes_threshold,
            } = standardLivingRiskPayload || { }

            const { chosen: originalChosen, recommended, preferred } = riskScores || { }
            const { lambda: chosenLambda, gamma: chosenGamma } = originalChosen
            const { lambda: recommendedLambda , gamma: recommendedGamma } = recommended
            
            const chosen = { lambda: chosenLambda !=null ? chosenLambda : recommendedLambda, gamma: chosenGamma != null ? chosenGamma : recommendedGamma }
            dispatch({ type: INVESTMENT_PORTFOLIO_UPDATE, payload: { chosen, recommended, preferred } })

            const startYearsMap: SelectOption[] = startYears.map((value: any) => { return { label: value, value } })
            const numberOfYearsMap: SelectOption[] = numberOfYears.map((value: any) => { return { label: value, value } })

            dispatch({ type: STANDARD_LIVING_RISK_RECEIVED, payload: { 
                    financial_plan_source,
                    years_in_financial_goal,
                    years_to_financial_goal,
                    discount_rate, inflation_rate,
                    investment_portfolio_today,
                    current_annual_savings,
                    planned_annual_savings_increase,
                    desired_annual_funding,
                    desired_annual_wants,
                    desired_annual_wishes,
                    montecarlo_needs_threshold: montecarlo_needs_threshold * 100,
                    montecarlo_wants_threshold: montecarlo_wants_threshold * 100,
                    montecarlo_wishes_threshold: montecarlo_wishes_threshold * 100,
                    standardLivingRisk,
                    income: irregularIncome,
                    expense:irregularExpenses,
                    pv_savings,
                    pv_liabilities,
                    assets_discreationary_wealth,
                    needsPortfolio: needs_portfolio,
                    wantsPortfolio: wants_portfolio,
                    wishesPortfolio: wishes_portfolio,
                    startYears: startYearsMap,
                    numberOfYears: numberOfYearsMap,
                    percentiles: montecarlo_percentiles,
                    percentileSeries: getMontecarloPercentiles(montecarlo_percentiles),
                    needs_immunization_period,
                    needs_model_id,
                    wants_model_id,
                    wishes_model_id,
                 }})
        } catch(e: any) {
            dispatch(addError('Standard Living Risk', e.toString()))
        }
    }
}

export const sendStandardLivingRiskUpdate = (payload: UpdateStandardLivingRiskRequest): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        try {
            const profileId = getPathsClientId()
            if (!profileId) {
                throw new Error('No Profile Id');
            }

            const { data } = await axios.put(`${CLIENTS_ENDPOINT}/${profileId}/${STANDARD_LIVING_RISK_ENDPOINT}`, payload)
            const { pv_savings, pv_liabilities, standardLivingRisk, assets_discreationary_wealth, recommended, startYears, numberOfYears, 
                    montecarlo_needs_threshold, montecarlo_wants_threshold, montecarlo_wishes_threshold } = data
            const { gamma, lambda } = recommended

            const startYearsMap = startYears.map((value: any) => { return { label: value, value } })
            const numberOfYearsMap = numberOfYears.map((value: any) => { return { label: value, value } })

            dispatch(updatePortfolioRiskParameter('recommended', 'gamma', gamma ))
            dispatch(updatePortfolioRiskParameter('recommended', 'lambda', lambda ))

            dispatch({ type: STANDARD_LIVING_RISK_RECEIVED, payload: { pv_savings, pv_liabilities, standardLivingRisk, assets_discreationary_wealth, startYears: startYearsMap, numberOfYears: numberOfYearsMap, montecarlo_needs_threshold: montecarlo_needs_threshold * 100, montecarlo_wants_threshold: montecarlo_wants_threshold * 100, montecarlo_wishes_threshold: montecarlo_wishes_threshold * 100 } })

        } catch(e: any) {
            console.log(e)
            dispatch(addError('Standard Living Risk', e.toString()))
        }
    }
}

export const onIrregularItemCreate = (type: IrregularItemType): StandardThunkAction => {
    return async(dispatch, getStore): Promise<void> => {
        try {
            const { standardLivingRisk } = getStore()
            const { [type]: irregularItems = [] } = standardLivingRisk
            

            const profileId = getPathsClientId()

            const { data } = await axios.post(`${CLIENTS_ENDPOINT}/${profileId}/${IRREGULAR_ITEM_ENDPOINT}`, { type })
            const { irregularItem, success } = data

            if(!success) {
                return
            }

            const updatedIrregularItems = [...irregularItems, irregularItem]


            dispatch({ type: STANDARD_LIVING_RISK_RECEIVED, payload: { [type]: updatedIrregularItems }})
        } catch(e: any) {
            dispatch(addError('Standard Living Risk', e.toString()))
        }
    }
}

export const onUpdateIrregularItem = (type: IrregularItemType, item: IrregularItem): StandardThunkAction => {
    return async(dispatch, getStore): Promise<void> => {
        try {
            const { standardLivingRisk } = getStore()
            const { [type]: irregularItems } = standardLivingRisk

            const updatedIrregularItems = irregularItems.map((irregularItem: IrregularItem ) => {
                if(item.id == irregularItem.id) {
                    return { ...irregularItem, ...item }
                }

                return { ...irregularItem }
            })

            dispatch({ type: STANDARD_LIVING_RISK_RECEIVED, payload: { [type]: updatedIrregularItems }})
            
            

            const profileId = getPathsClientId()
            const { data } = await axios.put(`${CLIENTS_ENDPOINT}/${profileId}/${IRREGULAR_ITEM_ENDPOINT}/${item.id}`, { ...item, value: sanatizeNumericValue(item.value), type })
            const { success } = data
            const { pv_savings, pv_liabilities, standardLivingRisk: slr, assets_discreationary_wealth, recommended } = data
            dispatch({ type: STANDARD_LIVING_RISK_RECEIVED, payload: { pv_savings, pv_liabilities, standardLivingRisk: slr, assets_discreationary_wealth } })

            if(!success) {
                return
            }
            const { gamma, lambda } = recommended

            dispatch(updatePortfolioRiskParameter('recommended', 'gamma', gamma ))
            dispatch(updatePortfolioRiskParameter('recommended', 'lambda', lambda ))
        } catch(e: any) {
            dispatch(addError('Standard Living Risk', e.toString()))
        }
    }
}

export const onIrregularItemDelete = (type: IrregularItemType, item: IrregularItem): StandardThunkAction => {
    return async(dispatch, getStore): Promise<void> => {
        try {
            const { standardLivingRisk } = getStore()
            const { [type]: irregularItems } = standardLivingRisk

            const profileId = getPathsClientId()
            const { data } = await axios.delete(`${CLIENTS_ENDPOINT}/${profileId}/${IRREGULAR_ITEM_ENDPOINT}/${item.id}`)
            const { success, pv_savings, pv_liabilities, standardLivingRisk: slr, assets_discreationary_wealth, recommended } = data

            if(!success) {
                return
            }
            const { gamma, lambda } = recommended

            dispatch(updatePortfolioRiskParameter('recommended', 'gamma', gamma ))
            dispatch(updatePortfolioRiskParameter('recommended', 'lambda', lambda ))
            
            dispatch({ type: STANDARD_LIVING_RISK_RECEIVED, payload: { pv_savings, pv_liabilities, standardLivingRisk: slr, assets_discreationary_wealth } })
            const updatedIrregularItems = irregularItems.filter(({ id }: IrregularItem) => id != item.id)

            dispatch({ type: STANDARD_LIVING_RISK_RECEIVED, payload: { [type]: updatedIrregularItems }})
            
        } catch(e: any) {
            dispatch(addError('Standard Living Risk', e.toString()))
        }
    }
}

export const yearsToFinancialGoalUpdate = (payload: number): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        dispatch({ type: YEARS_TO_FINANCIAL_GOAL, payload })
        dispatch(sendStandardLivingRiskUpdate({ years_to_financial_goal: sanatizeNumericValue(payload) }))
    }
}

export const yearsOfFinancialGoalUpdate = (payload: number | string): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        dispatch({ type: YEARS_OF_FINANCIAL_GOAL, payload })
        dispatch(sendStandardLivingRiskUpdate({ years_in_financial_goal: sanatizeNumericValue(payload) }))
    }
}

export const discountRateUpdate = (payload: number | string): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        dispatch({ type: DISCOUNT_RATE, payload })
        dispatch(sendStandardLivingRiskUpdate({ discount_rate: sanatizeNumericValue(payload) }))
    }
}

export const inflationRateUpdate = (payload: number | string): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        dispatch({ type: INFLATION_RATE, payload })
        dispatch(sendStandardLivingRiskUpdate({ inflation_rate: sanatizeNumericValue(payload) }))
    }
}

export const montecarloNeedsThresholdUpdate = (payload: number | string): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        dispatch({ type: MONTECARLO_NEEDS_THRESHOLD, payload })
        dispatch(sendStandardLivingRiskUpdate({ montecarlo_needs_threshold: sanatizeNumericValue(payload) / 100 }))
    }
}

export const montecarloWantsThresholdUpdate = (payload: number | string): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        dispatch({ type: MONTECARLO_WANTS_THRESHOLD, payload })
        dispatch(sendStandardLivingRiskUpdate({ montecarlo_wants_threshold: sanatizeNumericValue(payload) / 100 }))
    }
}

export const montecarloWishesThresholdUpdate = (payload: number | string): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        dispatch({ type: MONTECARLO_WISHES_THRESHOLD, payload })
        dispatch(sendStandardLivingRiskUpdate({ montecarlo_wishes_threshold: sanatizeNumericValue(payload) / 100 }))
    }
}

export const investmentPortfolioTodayUpdate = (payload: number | string): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        dispatch({ type: INVESTMENT_PORTFOLIO_TODAY, payload })
        dispatch(sendStandardLivingRiskUpdate({ investment_portfolio_today: sanatizeNumericValue(payload) }))
    }
}

export const currentAnnualSavingsUpdate = (payload: number | string): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        dispatch({ type: CURRENT_ANNUAL_SAVINGS, payload })
        dispatch(sendStandardLivingRiskUpdate({ current_annual_savings: sanatizeNumericValue(payload) }))
    }
}

export const plannedAnnualSavingsUpdate = (payload: number | string): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        dispatch({ type: PLANNED_ANNUAL_SAVINGS, payload })
        dispatch(sendStandardLivingRiskUpdate({ planned_annual_savings_increase: sanatizeNumericValue(payload) }))
    }
}

export const desiredAnnualFundingUpdate = (payload: number | string): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        dispatch({ type: DESIRED_ANNUAL_FUNDING, payload: payload || 0 })
        dispatch(sendStandardLivingRiskUpdate({ desired_annual_funding: sanatizeNumericValue(payload) }))
    }
}

export const desiredAnnualWantsUpdate = (payload: number | string): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        dispatch({ type: DESIRED_ANNUAL_WANTS, payload: payload || 0 })
        dispatch(sendStandardLivingRiskUpdate({ desired_annual_wants: sanatizeNumericValue(payload) }))
    }
}

export const desiredAnnualWishesUpdate = (payload: number | string): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        dispatch({ type: DESIRED_ANNUAL_WISHES, payload: payload || 0 })
        dispatch(sendStandardLivingRiskUpdate({ desired_annual_wishes: sanatizeNumericValue(payload) }))
    }
}

export const needsPortfolioModelUpdate = ({ value }: SelectOption): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        dispatch({ type: STANDARD_LIVING_RISK_UPDATE, payload: { needs_model_id: value as number } })
        await dispatch(sendStandardLivingRiskUpdate({ needs_model_id: value as number }))
        dispatch(calculateNeedsPortfolioInTime());
    }
}

export const needsImmunizationPeriodUpdate = ({ value }: SelectOption): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        dispatch({ type: STANDARD_LIVING_RISK_UPDATE, payload: { needs_immunization_period: value as NeedsImmunizationPeriod } })
        await dispatch(sendStandardLivingRiskUpdate({ needs_immunization_period: value as NeedsImmunizationPeriod }))
        dispatch(calculateNeedsPortfolioInTime());
    }
}

export const wantsPortfolioModelUpdate = ({ value }: SelectOption): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        dispatch({ type: STANDARD_LIVING_RISK_UPDATE, payload: { wants_model_id: value as number } })
        await dispatch(sendStandardLivingRiskUpdate({ wants_model_id: value as number }))
        dispatch(calculateNeedsPortfolioInTime());
    }
}

export const wishesPortfolioModelUpdate = ({ value }: SelectOption): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        dispatch({ type: STANDARD_LIVING_RISK_UPDATE, payload: { wishes_model_id: value as number } })
        await dispatch(sendStandardLivingRiskUpdate({ wishes_model_id: value as number }))
        dispatch(calculateNeedsPortfolioInTime());
    }
}

export const backToStart = (): StandardAction => {
    return { type: SLR_PAGE_INDEX, payload: 0 }
}

export const onSLRPage = (index: number): StandardAction => {
    return { type: SLR_PAGE_INDEX, payload: index }
}


export const calculateNeedsPortfolioInTime = (): StandardThunkAction => {
    return async(dispatch, getState: GetStateFunction): Promise<void> => {
        try {
            dispatch({ type: STANDARD_LIVING_RISK_RECEIVED, payload: { calculatingNeeds: true  } })
            const profileId = getPathsClientId()
            if (!profileId) {
                return;
            }
            const { data } = await axios.post(`${CLIENTS_ENDPOINT}/${profileId}/needs/time`)
            const { success, needsPortfolio, wantsPortfolio, wishesPortfolio, needsPortfoliosInTime, wantsWishesPortfoliosInTime, isTreasurey,
                    needsMontecarlo, wantsMontecarlo, wishesMontecarlo } = data || { }

            const montecarloSuccessRate = {
                needs: needsMontecarlo?.success_rate,
                wants: wantsMontecarlo?.success_rate,
                wishes: wishesMontecarlo?.success_rate,
            }
            const montecarloResults: MontecarloResultSet = {
                needs: needsMontecarlo,
                wants: wantsMontecarlo,
                wishes: wishesMontecarlo,
            }

            if(!success ||!needsPortfoliosInTime) {
                dispatch({ type: STANDARD_LIVING_RISK_RECEIVED, payload: { calculatingNeeds: false  } })
                return;
            }
            const needsBuckets = !isTreasurey ? needsPortfoliosInTime.filter((item: Bucket) => item.name.includes('Needs Portfolio')) : needsPortfoliosInTime?.map((bucket: Bucket) => ({ ...bucket, name: bucket.name.includes('Needs Portfolio') ? bucket.name : `${bucket.name} Treasury Portfolio` }))

            const needsInTimeChart = needsPortfoliosInTime ? generatePortfolioChartOptions(montecarloResults.needs.portfolio_values) : undefined;
            const wantsInTimeChart = needsPortfoliosInTime ? generatePortfolioChartOptions(montecarloResults.wants.portfolio_values) : undefined;
            const wishesInTimeChart = needsPortfoliosInTime ? generatePortfolioChartOptions(montecarloResults.wishes.portfolio_values) : undefined;
            
            dispatch({ type: STANDARD_LIVING_RISK_RECEIVED, payload: { montecarloSuccessRate, montecarloResults, needsPortfolio, wantsPortfolio, wishesPortfolio, bands: needsPortfoliosInTime, wantsWishes: wantsWishesPortfoliosInTime, needsInTimeChart, wantsInTimeChart, wishesInTimeChart, calculatingNeeds: false } })
            
        } catch(e: any) {
            if (axios.isAxiosError(e)) {
                const axiosError = e as AxiosError
                if(axiosError.response) {
                    const { data } = axiosError.response
                    const { message } = (data || { }) as any 
                    const errorMessage = message ? SLR_ERROR_MAPPING[message] || message  : 'Unkown Error calculating Needs Portfolio'
                    dispatch(addError('Standard Living Risk', errorMessage))
                }
            } else {
                dispatch(addError('Standard Living Risk', `An unknown error occurred`))
            }
        }
        dispatch({ type: STANDARD_LIVING_RISK_RECEIVED, payload: { calculatingNeeds: false  } })
    }
}

const getMontecarloPercentiles = (percentiles: MontecarloPercentiles = {}) => {
    const percentileKeys = Object.keys(percentiles)
    const percentilesSeries = percentileKeys.map((percentileKey: string) => {
        const percentile = percentiles[percentileKey]
        const data = Object.keys(percentile).map((year) => [Number(year), percentile[year]])
        return {
            name: percentileKey,
            data,
        }  
    })

    return percentilesSeries
}

export const runMontecarlo = ({ investment_portfolio_today, cashflows, expected_return, expected_volatility  }: { investment_portfolio_today: number, expected_return: number, expected_volatility: number, cashflows: { [key: string]: number } }): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        try {
            const payload = {
                iterations: 1000,
                startingCapital: investment_portfolio_today,
                cashflows: cashflows,
                expectedReturn: expected_return,
                expectedVolatility: expected_volatility,
            }
        
            const { data } = await axios.post(MONTECARLO_URL, payload)
            const { success_rate } = data as MontecarloResponse
            
            dispatch({ type: STANDARD_LIVING_RISK_RECEIVED, payload: { success_rate }})
        } catch(e: any) {
            console.error(e)
            dispatch(addError('Standard Living Risk', `Unexpected Error Loading Montecarlo`))
        }
    }
}

const generatePortfolioChartOptions = (portfolioValues: number[][]) => {
    const years = portfolioValues.length;
    const categories = Array.from({ length: years }, (_, i) => `${i}`);
  
    // Extract percentiles
    const percentiles = [5, 10, 25, 50, 75, 90, 95];
    const seriesData = percentiles.map((percentile, index) => ({
      name: `${percentile}th Percentile`,
      data: portfolioValues.map((yearData) => yearData[index]),
      marker: {
        enabled: false, // Remove markers from the chart
      },
    }));
  
    return {
      chart: {
        type: "line",
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: categories,
        title: {
          text: "Years",
          style: {
            fontSize: "12px", // Font size for the X-axis title
          },
        },
        labels: {
          style: {
            fontSize: "12px", // Font size for the X-axis labels
          },
        },
      },
      yAxis: {
        title: {
          text: "",
        },
        labels: {
            style: {
                fontSize: '12px'
            },
            formatter: function (): any {
                return `$${Highcharts.numberFormat(this.value, 0, ".", ",")}`;
            },
        },
      },
      tooltip: {
        shared: true,
        valuePrefix: "$",
        valueDecimals: 2,
        style: {
          fontSize: '12px',
        },
      },
      legend: {
        layout: "horizontal",
        align: "center",
        verticalAlign: "bottom",
        itemStyle: {
          fontSize: "12px", 
        },
      },
      series: seriesData,
    };
  };

  
export const getNeedsInTimeChart = ( bucket: Bucket[] = [], { showLegend }: NeedChartOptions = {} ): any => {
    const series = bucket.map((bucket: Bucket) => {
        const { name, lineStyle, needCashFlows } = bucket
        if (!needCashFlows) {
            return { name, data: [], lineStyle: lineStyle.toLowerCase() };
        }
        
        const data = Object.keys(needCashFlows).map((year) => [Number(year), needCashFlows[year]])

        return {
            name,
            data,
            dashStyle: lineStyle.toLowerCase(),
        }
    })
    

    return {
        chart: {
            type: 'spline'
        },
        title: {
            text: ''
        },
        xAxis: {
            title: {
                text: '<span style="font-size: 16px;">Year</span>',
            },
            labels: {
                style: {
                    fontSize: '14px'
                }
            },
            tickInterval: 5 // This sets the interval for x-axis labels
        },
        yAxis: {
            title: {
                text: ''
            },
            labels: {
                style: {
                    fontSize: '14px'
                },
                formatter: function(): string {
                    const value = this.value;
                    let formattedValue = '';
                  
                    if (value >= 1000000000) {
                      formattedValue = Highcharts.numberFormat(value / 1000000000, 2, '.') + 'B';
                    } else if (value >= 1000000) {
                      formattedValue = Highcharts.numberFormat(value / 1000000, 2, '.') + 'M';
                    } else if (value >= 1000) {
                      formattedValue = Highcharts.numberFormat(value / 1000, 0, '.') + 'k';
                    } else {
                      formattedValue = value.toString();
                    }
                  
                    return formattedValue;
                },
            },
            min: 0, // Set min property to 0 for y-axis
        },
        plotOptions: {
            spline: {
                dataLabels: {
                    enabled: false
                },
                marker: {
                    enabled: false
                }
            }
        },
        legend: {
            enabled: showLegend,
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'top',
            symbolWidth: 35,
            symbolHeight: 1,
            symbolRadius: 1,
            squareSymbol: true,
        },
        series
    }
}



export const runMontecarloForGraph = async (type: string) => {
    try {
        const clientId = getPathsClientId()
        const safeType = type === 'wish' ? 'wishes' : `${type}s`
        const { data } = await axios.get(`${CLIENTS_ENDPOINT}/${clientId}/${MONTECARLO_GRAPH_ENDPOINT}/${safeType}`)
        const { success, message, success_rate, portfolio_values_graph } = data
    
        if (!success) {
            throw new Error(message ? message : 'Unexpected Error Loading Montecarlo')
        }

        return { success_rate, portfolio_values_graph }
    } catch (e) {
        console.error(e)
        throw new Error('Unexpected Error Loading Montecarlo')
    }
}