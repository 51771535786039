import { Box } from '@mui/material'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { addProfileHistory } from '../../actions/profileHistory'
import { Size, SizeAwareBox } from '../common/styled/SizeAwareBox'
import Text from '../common/styled/Text'
import { WithRouterProps, withRouter } from '../common/withRouter'
import Button from '../common/Button'
import { WithClientParameterProps, withClientParameter } from '../common/withClientParameter'
import { onSelectedKeyChanged } from '../../actions/session'
import SessionUserDetailsSync from '../../common/SessionUserDetailsSync'

interface ReturnHomeProps extends WithRouterProps, WithClientParameterProps {
    onComponentSize?: (size: Size) => void
    addProfileHistory: () => void
    onSelectedKeyChanged: (page: string) => void
}
class ReturnHome extends Component <ReturnHomeProps>{

    componentDidMount(): void {
        this.props.addProfileHistory()
    }

    onBack = () => {
        this.props.onSelectedKeyChanged('standardLivingRisk')
    }

    render() {
        const { clientId } = this.props;

        return (
            <SizeAwareBox sx={{ width: '100%' }} onComponentSize={this.props.onComponentSize}>
                <SessionUserDetailsSync />
                <Box sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '50rem',
                }}>
                    <Box 
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                        <Text sx={{ margin: '1rem', marginBottom: '5rem' }}>Thank you for completing your client's financial plan. You may now return to the software to see the results.</Text>
                        <Button onClick={this.onBack}>Back to Financial Plan</Button>
                    </Box>
                </Box>
            </SizeAwareBox>
        )
    }
}

export default connect(null, { addProfileHistory, onSelectedKeyChanged })(withClientParameter(withRouter(ReturnHome)))