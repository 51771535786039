import { Component } from 'react'

import Page from '../Page/Page';
import { PageContents } from '../Page/PageContents';
import ClientManagement from './ClientManagement'

class ClientManagementPage extends Component {

    render() {
        return (
            <Page sx={{ width: '100%' }} >
                <PageContents 
                    sx={{
                        marginTop: '5rem',
                        paddingTop: '5rem',
                        minHeight: '80rem',
                        height: '100%',
                        position: 'relative',
                        overflowX: 'scroll',
                    }}
                >
                    <ClientManagement />
                </PageContents>
            </Page>
        )
    }

}

export default ClientManagementPage