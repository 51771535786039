import { FinancialPlanSource, FundingType, MontecarloResultSet, NeedsImmunizationPeriod } from '../actions/types/standardLivingRisk';
import { 
    STANDARD_LIVING_RISK_PAGE_INDEX,
    SUBMIT_SUCCESS,
    STANDARD_LIVING_RISK_UPDATE,
    YEARS_TO_FINANCIAL_GOAL,
    YEARS_OF_FINANCIAL_GOAL,
    DISCOUNT_RATE,
    INFLATION_RATE,
    STANDARD_LIVING_RISK_LOADING,
    STANDARD_LIVING_RISK_RECEIVED,
    INVESTMENT_PORTFOLIO_TODAY,
    CURRENT_ANNUAL_SAVINGS,
    PLANNED_ANNUAL_SAVINGS,
    DESIRED_ANNUAL_FUNDING,
    DESIRED_ANNUAL_WANTS,
    DESIRED_ANNUAL_WISHES,
    SLR_PAGE_INDEX,
    LOGOUT,
    PRINT_DETAILS_RECEIVED,
    PROFILE_RESET,
    MONTECARLO_NEEDS_THRESHOLD,
    MONTECARLO_WANTS_THRESHOLD,
    MONTECARLO_WISHES_THRESHOLD,
} from '../actions/types/actions';
import { LooseObject, SelectOption } from '../common/types';
import { ChartPayloadOptions } from '../actions/types/profileHistory'

export interface StandardLivingRiskState {
    activeIndex: number
    years_to_financial_goal: number
    years_in_financial_goal: number
    discount_rate: number
    inflation_rate: number
    montecarlo_needs_threshold: number
    montecarlo_wants_threshold: number
    montecarlo_wishes_threshold: number
    investment_portfolio_today: number
    current_annual_savings: number
    planned_annual_savings_increase: number
    desired_annual_funding: number
    desired_annual_wants: number
    desired_annual_wishes: number
    standardLivingRisk: number
    startYears: number[]
    numberOfYears: number[]
    bands: any[]
    wantsWishes: any[]
    needsPortfolio: number
    wantsPortfolio: number
    wishesPortfolio: number
    needsWentNegative: boolean
    needs_portfolio_method: SelectOption | null
    percentilesSeries: any[]
    calculatingNeeds: boolean;
    needsInTimeChart?: LooseObject
    wantsInTimeChart?: ChartPayloadOptions;
    wishesInTimeChart?: ChartPayloadOptions;
    financial_plan_source: FinancialPlanSource;
    needs_immunization_period: NeedsImmunizationPeriod;
    needs_model_id?: number;
    wants_model_id?: number;
    wishes_model_id?: number;
    montecarloSuccessRate?: {
        needs: number;
        wants: number;
        wishes: number;
    }
    montecarloResults?: MontecarloResultSet;
}

const INITIAL_STATE: StandardLivingRiskState = {
    activeIndex: 0,
    years_to_financial_goal: 0,
    years_in_financial_goal: 0,
    discount_rate: 0,
    inflation_rate: 0,
    montecarlo_needs_threshold: 0.75,
    montecarlo_wants_threshold: 0.75,
    montecarlo_wishes_threshold: 0.75,
    investment_portfolio_today: 0,
    current_annual_savings: 0,
    planned_annual_savings_increase: 0,
    desired_annual_funding: 0,
    desired_annual_wants: 0,
    desired_annual_wishes: 0,
    standardLivingRisk: 0,
    startYears: [],
    numberOfYears: [],
    bands: [],
    needsPortfolio: 0,
    wishesPortfolio: 0,
    wantsPortfolio: 0,
    needsWentNegative: false,
    needs_portfolio_method: null,
    percentilesSeries: [],
    calculatingNeeds: false,
    wantsInTimeChart: undefined,
    wishesInTimeChart: undefined,
    financial_plan_source: FinancialPlanSource.PORTFOLIO_DESIGNER,
    needs_immunization_period: NeedsImmunizationPeriod.FIVE_YEAR,
    montecarloSuccessRate: undefined,
    montecarloResults: undefined,
    needsInTimeChart: undefined,
};

export default (state = INITIAL_STATE, action): StandardLivingRiskState => {
  const { payload, type } = action

  switch (type) {
    case STANDARD_LIVING_RISK_LOADING:
        return { ...state, ...payload, loading: true }
    case PRINT_DETAILS_RECEIVED:
        return { ...state, ...(payload.slr || { }), loading: true }
    case STANDARD_LIVING_RISK_RECEIVED:
        return { ...state, ...payload, loading: false }
    case YEARS_TO_FINANCIAL_GOAL:
        return { ...state, years_to_financial_goal: payload }
    case YEARS_OF_FINANCIAL_GOAL:
        return { ...state, years_in_financial_goal: payload }
    case DISCOUNT_RATE:
        return { ...state, discount_rate: payload }
    case INFLATION_RATE:
        return { ...state, inflation_rate: payload }
    case MONTECARLO_NEEDS_THRESHOLD:
        return { ...state, montecarlo_needs_threshold: payload }
    case MONTECARLO_WANTS_THRESHOLD:
        return { ...state, montecarlo_wants_threshold: payload }
    case MONTECARLO_WISHES_THRESHOLD:
        return { ...state, montecarlo_wishes_threshold: payload }
    case INVESTMENT_PORTFOLIO_TODAY:
        return { ...state, investment_portfolio_today: payload }
    case CURRENT_ANNUAL_SAVINGS:
        return { ...state, current_annual_savings: payload }
    case PLANNED_ANNUAL_SAVINGS:
        return { ...state, planned_annual_savings_increase: payload }
    case STANDARD_LIVING_RISK_PAGE_INDEX:
        return { ...state, activeIndex: payload }
    case DESIRED_ANNUAL_FUNDING:
        return { ...state, desired_annual_funding: payload }
    case DESIRED_ANNUAL_WANTS:
        return { ...state, desired_annual_wants: payload }
    case DESIRED_ANNUAL_WISHES:
        return { ...state, desired_annual_wishes: payload }
    case SLR_PAGE_INDEX:
        return { ...state, activeIndex: payload }
    case STANDARD_LIVING_RISK_UPDATE:
        return { ...state, ...payload }
    case PROFILE_RESET:
    case LOGOUT:
      return { ...state, ...INITIAL_STATE }
    default:
        return state;
  }
};

