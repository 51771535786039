import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Tabs, Tab } from 'react-bootstrap'
import { Styles, withStyles } from '@mui/styles'
import { Box } from '@mui/material';

import { onSelectedKeyChanged, profileReset } from '../../actions/session';
import { backToStart as riskPrefBackToStart, runRiskAnalyzerResults } from '../../actions/riskPreferences';
import { backToStart as slrPrefBackToStart } from '../../actions/standardLivingRisk';
import { renderCharts } from '../../actions/investmentPortfolio';
import Header from '../ClientProfile/Header';
import Page from '../Page/Page';
import VIEWS from './viewsMapping'
import { withRouter, WithRouterProps } from '../common/withRouter';
import { withAuth, withAuthProps } from '../../common/hooks/withAuth';
import { WithClientParameterProps, withClientParameter } from '../common/withClientParameter';
import { Membership } from '../../actions/types/users';
import { ReduxState } from '../../reducers';
import { fontWeight } from 'html2canvas/dist/types/css/property-descriptors/font-weight';

const VIEW_KEYS = Object.keys(VIEWS)
const VALID_PORTFOLIO_MANAGERS = ['orion']

const stylesFunction: Styles<any, any> = () =>{
    return {
        '@global': {
            "html body": {
                background: '#ecf0f5'
            },
            '.nav-tabs': {
                borderBottom: 'unset',
            },
            '.nav-tabs > a.nav-item.active': {
                overflow: 'visible',
                borderTop: '1px solid #92d2e2',
                borderLeft: '1px solid #92d2e2',
                borderRight: '1px solid #92d2e2',
                borderBottom: '1px solid white',
                backgroundColor: '#ffffff',
                color: '#171443',
                paddingTop: '20px',
                paddingLeft: '25px',
                paddingRight: '25px',
                fontWeight: 'bold',
            },
            '.nav-tabs > a': {
                backgroundColor: '#d3e0ee',
                color: '#171443',
                fontSize: '14px',
                fontWeight: '500',
                paddingTop: '10px',
                paddingBottom: '12px',
                paddingLeft: '20px',
                paddingRight: '20px',
                top: '-7px',
                borderRadius: '0 0 0 0',
                borderBottom: '1px solid #92d2e2',
            },
            '.tab-content': {
                border: '1px solid #92d2e2',
                backgroundColor: '#ffffff',
                float: 'left',
                padding: '30px',
                marginBottom: '10px',
                marginTop: '9px',
                overflow: 'visible',
            },
            '.tab-content>.tab-pane': {
                display: 'block',
                height: 0,
                overflow: 'hidden',
            },
            '.tab-content>.tab-pane.active': {
                display: 'unset',
                height: 'auto',
                overflow: 'hidden',
            },
        }
    }
}

interface SoftwareProps extends WithRouterProps, withAuthProps, WithClientParameterProps {
    onSelectedKeyChanged: (key: string | null, backToStart: boolean) => void
    profileReset: () => void
    selectedKey: string
    sessionLoaded: boolean
    activeIndexKeys: string[]
    portfolio_manager: string
}

class Software extends Component<SoftwareProps> {
    pageRef: any
    constructor(props: SoftwareProps) {
        super(props);
    }

    componentDidMount(): void {
        const { selectedKey, clientId, location, user } = this.props
        const pathName = location.pathname
        const isSoftwarePage = pathName.startsWith('/software')
        const tabsDisabled = isSoftwarePage && user?.membership === Membership.ADVISOR_WORKSTATION && !clientId;
        if (selectedKey !== 'clients' && !clientId && tabsDisabled) {
            this.props.onSelectedKeyChanged('clients', true)
        }
    }

    componentDidUpdate(prevProps: SoftwareProps) {
        const didSelectKeyChange = this.didValueChange('selectedKey', prevProps)
        const didClientIdChange = this.didValueChange('clientId', prevProps)

        if (didSelectKeyChange || didClientIdChange) {
            const { selectedKey, clientId, location, user } = this.props
            const pathName = location.pathname
            const isSoftwarePage = pathName.startsWith('/software')
            const tabsDisabled = isSoftwarePage && user?.membership === Membership.ADVISOR_WORKSTATION && !clientId;
            if (selectedKey !== 'clients' && !clientId && tabsDisabled) {
                this.props.onSelectedKeyChanged('clients', true)
            }
        }
        if (didSelectKeyChange) {
            this.pageRef?.scrollToTop();
        } else {
            const {activeIndexKeys} = this.props
            for(const activeIndexKey of activeIndexKeys) {
                if (this.didValueChange(activeIndexKey as keyof SoftwareProps, prevProps)) {
                    this.pageRef.scrollToTop();

                    break;
                }    
            }
        }
    }

    didValueChange = (key: keyof SoftwareProps, prevProps: SoftwareProps) => {
        const { [key]: currentValue } = this.props

        return prevProps[key] !== currentValue
    }

    render() {
        const { selectedKey, loading, userDetails, user, location, portfolio_manager, clientId } = this.props
        const { views: rawViews = [], config = [], } = userDetails || { }

        const pathName = location.pathname
        const isSoftwareRootPage = pathName === '/software'
        const isSoftwarePage = pathName.startsWith('/software')

        const showHeader = !config.includes('no_header')
        let views = rawViews.filter((view) => {
            const item = VIEWS[view];
            if (!item) {
                return false;
            }
            if (isSoftwarePage) {
                return !item.paths || item.paths?.includes(pathName);
            }

            return item.paths?.includes(pathName)
        })
        views = [...views].sort((a, b) => {
            if (VIEWS[a].order !== undefined && VIEWS[b].order  !== undefined ) {
                return (VIEWS[a].order ?? 0) - (VIEWS[b].order ?? 0);
            }
            const aIndex = views.indexOf(a);
            const bIndex = views.indexOf(b);

            return aIndex - bIndex;
        });
        const tabsDisabled = isSoftwarePage && user?.membership === Membership.ADVISOR_WORKSTATION && !clientId;

        const defaultKey = views.length > 0 ? VIEWS[views[0]].eventKey : 'riskPreferences'
        const selectedKeyValid = views.includes(VIEW_KEYS.find((key) => VIEWS[key]?.eventKey == selectedKey) || '')
        const activeKey = selectedKeyValid && !isSoftwareRootPage ? selectedKey : defaultKey
        if(loading) {
            return <div></div>
        }

        return (
                <>
                <Box sx={{
                        '@media print': {
                            display: 'none',
                        }
                    }}>
                        <Page innerRef={(view: Page) => this.pageRef = view}>
                            <Box sx={{ marginTop: '2rem' }}>
                                {showHeader &&
                                    <Header />
                                }
                                <Tabs defaultActiveKey={defaultKey} transition={false} activeKey={tabsDisabled ? 'clients' : activeKey} onSelect={(key) => this.props.onSelectedKeyChanged(key, true)}>
                                    {views.map((key) => {
                                        const { eventKey, title, component: ViewComponent } = VIEWS[key] || { }
                                        return (
                                            <Tab key={eventKey} eventKey={eventKey} title={title} disabled={tabsDisabled}>
                                                <ViewComponent />
                                            </Tab>
                                        )
                                    })}
                                </Tabs>
                            </Box>
                        </Page>
                    </Box>
                    <Box sx={{
                            display: 'none',
                            '@media print': {
                                display: 'flex',
                                height: '100%',
                                width: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }
                        }}>
                            Please use the Print button to initiate a print.
                    </Box>
                </>
        )
    }

}

const mapStateToProps = ({ session, profileHistory }: ReduxState) => {
    const { selectedKey, profile, error, riskPreferencesActiveIndex, riskAnalyzerActiveIndex, riskAnalyzerEnterpriseActiveIndex, standardLivingRiskActiveIndex } = session
    const { portfolio_manager } = profile || {}
    const { showAddHistorySuccess } = profileHistory
    const activeIndexes = { riskPreferencesActiveIndex, standardLivingRiskActiveIndex, riskAnalyzerActiveIndex, riskAnalyzerEnterpriseActiveIndex }
    const activeIndexKeys = Object.keys(activeIndexes)
    
    return { selectedKey, error, showAddHistorySuccess, ...activeIndexes, activeIndexKeys, portfolio_manager }
}

export default withStyles(stylesFunction)(withClientParameter(withAuth(withRouter(connect(mapStateToProps, { profileReset, onSelectedKeyChanged, riskPrefBackToStart, slrPrefBackToStart, renderCharts, runRiskAnalyzerResults })(Software)))))