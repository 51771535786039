import { Component } from "react";

export interface View {
    order?: number;
    eventKey: string
    title: string
    componentName: string
    component: any;
    pages?: string[]
    paths?: string[]
}

export interface ViewCollection {
    [key: string]: View
}

const VIEWS: ViewCollection = {
    risk_analyzer: {
        eventKey: 'riskAnalyzer',
        title: 'Risk Analyzer',
        componentName: 'RiskAnalyzer',
        pages: [
            'riskAnalyzerResults',
        ]
    },
    risk_analyzer_enterprise: {
        eventKey: 'riskAnalyzerEnterprise',
        title: 'Risk Analyzer',
        componentName: 'RiskAnalyzer',
        pages: [
            'riskResults',
        ]
    },
    risk_analyzer_optimized_portfolio: {
        eventKey: 'riskAnalyzerOptimizedPortfolio',
        title: 'Optimized Portfolio',
        componentName: 'RiskAnalyzerOptimizedPortfolio',
    },
    risk_preferences: {
        order: 1,
        eventKey: 'riskPreferences',
        title: 'Risk Profile',
        componentName: 'RiskPreferences',
        pages: [
            'riskResults',
        ]
    },
    clients: {
        eventKey: 'clients',
        title: 'Clients',
        componentName: 'ClientManagementTab',
        pages: []
    },
    savings_goals: {
        eventKey: 'savingsGoals',
        title: 'Savings & Goals',
        componentName: 'ClientFinancialPlanTab',
        pages: [
            'financialGoal',
            'assets',
            'liabilities',
        ]
    },
    standard_living_risk: {
        order: 0,
        eventKey: 'standardLivingRisk',
        title: 'Financial Plan',
        componentName: 'StandardLivingRisk',
        pages: [
            'slr',
        ]
    },
    standard_living_risk_simple: {
        eventKey: 'standardLivingRiskSimple',
        title: 'ALM Framework',
        componentName: 'StandardLivingRiskSimple',
    },
    custom_cma: {
        eventKey: 'customCMA',
        title: 'Capital Mkt. Assumptions',
        componentName: 'CustomCMA',
    },
    optimized_portfolio: {
        eventKey: 'optimizedPortfolio',
        title: 'Optimized Portfolio',
        componentName: 'OptimizedPortfolio',
    },
    profile_history: {
        order: 2,
        eventKey: 'profileHistory',
        title: 'Profile History',
        componentName: 'ProfileHistory',
    },
    standalone_moderation: {
        eventKey: 'standAloneModeration',
        title: 'SLR-Adjusted Risk Preferences',
        componentName: 'StandaloneModeration',
    },
    single_use_software: {
        eventKey: 'singleUseSoftware',
        title: 'Risk Profile',
        componentName: 'StandaloneModeration',
        pages: [
            'riskAversion',
            'lossAversion',
            'riskAnalyzerOptimizedPortfolio',
        ]
    },
    client_questionnaire: {
        eventKey: 'clientQuestionnaire',
        title: 'Risk Profile',
        componentName: 'StandaloneModeration',
        pages: [
            'riskAversion',
            'lossAversion',
            'riskAnalyzerOptimizedPortfolio',
        ]
    },
    client_portfolio: {
        eventKey: 'clientPortfolio',
        title: 'Client Portfolio',
        componentName: 'ClientPortfolio',
    },
    portfolio_manager: {
        eventKey: 'portfolio_manager',
        title: 'Portfolio Manager',
        componentName: 'PortfolioManager',
    },
    financial_plan: {
        eventKey: 'financialPlan',
        title: 'Financial Plan',
        componentName: 'ClientFinancialPlan',
        pages: [
            'financialGoal',
            'assets',
            'liabilities',
            'return_to_home',
        ]
    },
    portfolio_designer: {
        eventKey: 'portfolio_designer',
        title: 'Model Builder',
        componentName: 'PortfolioDesigner',
        paths:['/designer', '/research']
    },
    forecast: {
        order: 3,
        eventKey: 'forecast',
        title: 'Forecasting Dashboard',
        componentName: 'Forecast'
    },
    due_diligence_charts: {
        eventKey: 'dueDiligenceCharts',
        title: 'Due Diligence',
        componentName: 'DueDiligenceCharts',
        paths:['/research', '/designer']
    },
    market_dashboard: {
        eventKey: 'marketDashboard',
        title: 'Market Dashboard',
        componentName: 'MarketDashboard',
        paths:['/research', '/designer']
    },
    simplify_performance_attribution: {
        eventKey: 'performanceAttribution',
        title: 'Performance Attribution',
        componentName: 'PerformanceAttribution',
        paths:['/simplify/tools']
    },
    simplify_risk_analyzer: {
        eventKey: 'simplifyRiskAnalyzer',
        title: 'Risk Analyzer',
        componentName: 'SimplifyRiskAnalyzer',
        paths:['/simplify/tools']
    },
}

export const VIEW_KEYS: string[] = Object.keys(VIEWS)

export const VIEW_COMPONENTS: ViewCollection =  VIEW_KEYS.reduce((accum, key) => {
    return { ...accum, [key]: { ...VIEWS[key], key }}
}, { })

export default VIEW_COMPONENTS;