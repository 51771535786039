import { Component } from 'react'

import { Box } from '@mui/material';
import ClientManagement from './ClientManagement';

export class ClientManagementTab extends Component {
    render() {
        return (
            <Box sx={{ width: '150rem', display: 'flex', flexDirection: 'row' }}>
                <ClientManagement />
            </Box>
        )
    }
}