import { Component } from 'react'
import { connect } from 'react-redux';

import { PageContents } from '../Page/PageContents';
import PagerContainer from '../RiskPreferences/PagerContainer';
import Page from '../Page/Page';
import { onPageChange } from '../../actions/session';
import FinancialGoal from './FinancialGoal';
import Assets from './Assets';
import Liabilities from './Liabilities';
import { hasClientIdChanged } from '../../common/utils';
import { AccountLogo } from '../common/styled/AccountLogo';
import ReturnHome from './ReturnHome';
import { Box } from '@mui/material';
import { getStandardLivingRisk } from '../../actions/standardLivingRisk';

export interface State {
    logo?: string
}

export interface ClientRiskPreferencesProps {
    onPageChange: (key: string, index: number) => void
    getStandardLivingRisk: () => void
    submitSuccess?: boolean
    loading?: boolean
    activeIndex: number
}

class ClientRiskPreferences extends Component <ClientRiskPreferencesProps, State> {
    constructor(props: any) {
        super(props);
        this.state = {
        }
    }

    async componentDidMount() {
        this.props.getStandardLivingRisk()
    }

    componentDidUpdate(prevProps: ClientRiskPreferencesProps) {
        if(hasClientIdChanged(prevProps, this.props)) {
            this.props.getStandardLivingRisk()
        }
    }

    componentWillUnmount(): void {
        this.props.onPageChange('savingsGoals', 0)
    }

    render() {
        const { activeIndex } = this.props

        return (
            <Box sx={{ width: '100rem', display: 'flex', flexDirection: 'row' }}>
                <PagerContainer activeIndex={activeIndex} gesturesEnabled={false} >
                    <FinancialGoal />
                    <Assets />
                    <Liabilities />
                </PagerContainer>
            </Box>
        )
    }
}

const mapStateToProps = ({ standardLivingRisk, session }) => {
    const { loading, submitSuccess } = standardLivingRisk
    const { firmWebsite, savingsGoalsActiveIndex } = session

    return {
        loading,
        activeIndex: savingsGoalsActiveIndex,
        submitSuccess,
        firmWebsite,
    }
}

export default connect(mapStateToProps, { getStandardLivingRisk, onPageChange })(ClientRiskPreferences)