import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactSearchBox from 'react-search-box'
import { Styles, withStyles } from '@mui/styles'
import { Box } from '@mui/material'

import { getAdvisorClients } from '../../actions/clientManagement'
import { withRouter, WithRouterProps } from '../common/withRouter'
import { BaseProps } from '../types'
import Text from '../common/styled/Text'
import { withAuth } from '../../common/hooks/withAuth'
import { UserDetails, AuthenticatedUser } from '../../actions/types/users'
import { AccountLogo } from '../common/styled/AccountLogo'
import { getPathsClientId } from '../../common/utils'
import { withWindowDimensions, WithWindowDimensionsProps } from '../common/withWindowDimensions'

export const styles: Styles<any, any> = (theme) => {
  return {
    headerContainer: {
      display: 'flex',
      flex: '1',
      maxHeight: 125,
      marginTop: '4rem',
      marginBottom: '2rem',
      width: '100%',
    },
    headerContents: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        flex: '1',
        height: 65,
    },
    selectClientOverlay: {
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        fontSize: '3.5rem',
        alignItems: 'center',
    },
    selectSearchContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    searchClientsContainer: {
        position: 'relative',
        height: '5rem',
        width: '30rem',
        marginRight: '5rem',
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    centerContainer: {
        display: 'flex',
        position: 'absolute',
        flex: 1,
        justifyContent: 'center',
        width: '100%',
    },
    addClient: {
        textDecoration: 'underline',
        '&:hover': {
          color: 'rgb(0, 255, 255)',
          cursor: 'pointer',
        }
    },
    searchBoxContainer: {
      position: 'absolute',
      top: 0,
      zIndex: 100,
      '& input': {
        borderRadius: '0px',
      }
    },
    screenOverlay: {
      width: '100%',
      height: '100%',
      backgroundColor: '#0005',
      position: 'fixed',
      zIndex: 500,
      top: '0px',
      left: '0px',
      right: '0px',
      bottom: '0px',
    },
    printContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: '1.5rem',
        paddingBottom: '1.5rem',
        marginTop: '8.5rem',
    },
    selectClientSearch: {
        position: 'absolute',
        top: '5px',
        right: '-24rem',
        zIndex: '10rem',
        height: '5rem',
        width: '20rem',
        fontSize: '1.4rem',
        color: '#333',
    },
    selectClientSubText: {
        fontSize: '2rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
  }
}

interface HeaderProps extends WithRouterProps, WithWindowDimensionsProps, BaseProps {
  getAdvisorClients: (simple?: boolean) => Promise<void>
  clients: any[]
  profileName: string
  logoAvailable: boolean
  user?: AuthenticatedUser
  userDetails?: UserDetails
}

interface HeaderState {
  searchText: string;
}

class Header extends Component<HeaderProps, HeaderState> {
    constructor(props: HeaderProps) {
        super(props)
        this.state = {
          searchText: '',
        }
    }
    
    componentDidMount() {
      this.props.getAdvisorClients(true)
    }

    onProfileChanged = ({item}) => {
      if(item) {
        this.props.navigate(`/software/clients/${item.id}`)
      }

      this.onSearchTextChanged('')
    }

    onAddPressed = () => {
      this.props.navigate(`/software/clients?add=1`)
    }

    onSearchTextChanged = (searchText: string) => {
      this.setState({ searchText })
    }

    render() {
        const { profileName, clients = [], logoAvailable, user, userDetails, classes, dimensions, location } = this.props
        const { enterprise } = user || {}
        const { searchText } = this.state
        const showLogo = logoAvailable || enterprise
        const overlayZIndex = profileName ? undefined : 501
        const showClientSearch = location.pathname.startsWith('/software/clients')

        return (
          <Box className={classes?.headerContainer} sx={{ width: dimensions.width }}>
            <div className={classes?.headerContents}>
              {showClientSearch && (
                  <Box sx={{
                    flex: 1,
                    display: 'flex',
                  }}>
                    <Text type='h2' sx={{ color: '#171443' }}>{profileName ?? ''}</Text>
                  </Box>
              )}
              <div className={classes?.centerContainer}>
                {showLogo &&
                    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', '& img': { maxWidth: 300, maxHeight: 60 } }}>
                        <AccountLogo />
                    </Box>
                }
              </div>
            </div>
          </Box>
        )
    }

}
const mapStateToProps = ({ session, clientManagement }) => {
    const { profile } = session
    const { clients, logoAvailable } = clientManagement
    let profileName = ''
    if(clients) {
      const clientId = getPathsClientId()
      const client = clients.find((client) => client.id == clientId)
      const { name } = client || { }
      profileName = name
    }

    return { profileName, clients, logoAvailable }
}

export default withStyles(styles)(withWindowDimensions(withAuth(withRouter(connect(mapStateToProps, { getAdvisorClients })(Header)))))